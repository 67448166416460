// PlayAnimationHTMLDocConOrden.tsx

import ReactDOMServer from "react-dom/server";
import { Frame } from "./DialogPlayBuilder";
import { BasketballFullCourt } from "./BasketballFullCourt.svg";
import { BasketballHalfCourt } from "./BasketballHalfCourt.svg";
import { BACKEND } from "../misc/Constants";

// -------------- 1) SVG CONTENT --------------
export function generateSVGContent(framesData: Frame[], courtType: string) {
  const courtSVG = ReactDOMServer.renderToStaticMarkup(
    courtType === "fullcourt" ? <BasketballFullCourt /> : <BasketballHalfCourt />
  );
  const [courtWidth, courtHeight] =
    courtType === "fullcourt" ? [910, 550] : [542, 454];

  // Collect all unique players & lines
  const allPlayers = framesData.flatMap((f) => f.players);
  const allLines = framesData.flatMap((f) => f.lines);

  // Generate one <g> per unique player
  const playersSVG = Array.from(new Set(allPlayers.map((p) => p.id)))
    .map((id) => {
      const player = allPlayers.find((p) => p.id === id);
      if (!player) return "";
      return `
        <g id="player-${player.id}" style="display: none;">
          ${player.hasBall
          ? `<circle cx="${player.x}" cy="${player.y}" r="15" stroke="black" stroke-width="2" fill="none" />`
          : ""
        }
          <text 
            x="${player.x}" 
            y="${player.y}" 
            text-anchor="middle" 
            alignment-baseline="middle" 
            font-family="Roboto, sans-serif" 
            font-size="22" 
            font-weight="bold" 
            fill="black"
          >
            ${player.number}
          </text>
        </g>
      `;
    })
    .join("");

  // Generate one <path> per unique line
  const linesSVG = Array.from(new Set(allLines.map((l) => l.id)))
    .map((id) => {
      const line = allLines.find((l) => l.id === id);
      if (!line) return "";
      return `
        <path 
          id="line-${line.id}"
          d="M0 0"
          stroke="black"
          stroke-width="2"
          fill="none"
          style="display: none;"
        />
      `;
    })
    .join("");


  /*
  on download, play2video, then:
  if first frame then upload opening tag and outer html of courtSVG and playersSVGlinesSVG
  if not first frame then upload outer html of playersSVGlinesSVG
  */
  const ressvg = `
    <svg 
      id="animation-svg" 
      viewBox="0 0 ${courtWidth} ${courtHeight}"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="courtSVG">
        ${courtSVG}
      </g>
      <g id="playersSVGlinesSVG">
        ${playersSVG}
        ${linesSVG}
      </g>
    </svg>
  `
  // console.log(ressvg)
  return ressvg;
}

// -------------- 2) ANIMATION SCRIPT --------------
export const generateAnimationScript = (framesData: Frame[], playNid: number, downloadVideo: boolean = false) => {

  // ---------------- CONSTANTS & SPEEDS (px/ms, or seconds) ----------------
  const DRAW_SPEED_PX_PER_MS = 0.2;       // how many px we draw per ms
  const MARKER_DELAY_SECONDS = 0.5;        // marker delay in seconds (fixed)
  const MOVEMENT_SPEED_PX_PER_MS = 0.25;    // how many px define the 'movement' time

  // We'll produce a metadata structure describing how long each group is
  type GroupMeta = {
    groupTimeMs: number;    // total time for this group
    drawTimeMs: number;     // portion for drawing
    markerTimeMs: number;   // portion for marker (fixed in seconds)
    moveTimeMs: number;     // portion for movement
    lines: any[];
  };

  // Prepare frame metadata (groups, etc.)
  const framesMeta = framesData.map((frame) => {
    // group lines by sameMomentAsPrevious
    const linesOrdered = [...frame.lines].sort(
      (a, b) => (a.order ?? 0) - (b.order ?? 0)
    );
    const groups: GroupMeta[] = [];
    let currentGroup: any[] = [];

    for (let i = 0; i < linesOrdered.length; i++) {
      const ln = linesOrdered[i];
      if (i === 0 || !ln.sameMomentAsPrevious) {
        currentGroup = [ln];
        groups.push({
          groupTimeMs: 0,
          drawTimeMs: 0,
          markerTimeMs: 0,
          moveTimeMs: 0,
          lines: currentGroup,
        });
      } else {
        currentGroup.push(ln);
      }
    }

    return {
      frame,
      groups,
      frameTimeMs: 0 // We'll fill after measuring in the browser
    };
  });

  const script = `
    // ----- Animation Data from the server -----
    const framesData = ${JSON.stringify(framesData)};
    let framesMeta = ${JSON.stringify(framesMeta)};

    // px/ms speeds and fixed marker delay in seconds
    const DRAW_SPEED_PX_PER_MS = ${DRAW_SPEED_PX_PER_MS};
    const MARKER_DELAY_SECONDS = ${MARKER_DELAY_SECONDS};
    const MOVEMENT_SPEED_PX_PER_MS = ${MOVEMENT_SPEED_PX_PER_MS};

    let totalDuration = 0;
    let cumulativeFrameDurations = [];

    // -------------- DOM & UI Setup --------------
    let isPlaying = false;
    let currentTime = 0;
    let lastTimestamp = null;
    let prevFrameIndex = -1;
    let videoCurrentFrame = 0;

    const playBtn = document.getElementById("play-pause");
    const progressBar = document.getElementById("progress-bar");
    const svgRoot = document.getElementById("animation-svg");
    const courtSVG = document.getElementById("courtSVG");
    const playersSVGlinesSVG = document.getElementById("playersSVGlinesSVG");

    const players = {};
    const lines = {};
    const movingElements = {};
    const endElements = {};

    window.onload = () => {
      // Gather DOM references
      framesData.forEach(f => {
        f.players.forEach(p => {
          const pe = document.getElementById("player-" + p.id);
          if (pe) players[p.id] = pe;
        });
        f.lines.forEach(l => {
          const le = document.getElementById("line-" + l.id);
          if (le) lines[l.id] = le;
        });
      });

      // 1) measure & compute actual times
      measureAndComputeTimings();

      // 2) start at time=0
      updateAnimation(0);
    };

    async function postFrame(filename, content) {
      // limit upload for test purposes
      //if (videoCurrentFrame > 40)
      //  return

      fetch('${BACKEND}/icb-playbook/play2video/${playNid}', {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
        },
        body: JSON.stringify({
          filename: filename,
          content: content,
        }),
      });
    }

    playBtn.onclick = () => {
      isPlaying = !isPlaying;
      playBtn.textContent = isPlaying ? "⏸" : "▶";
      if (isPlaying) {
        lastTimestamp = null;
        requestAnimationFrame(animate);
      }
    };

    progressBar.oninput = (e) => {
      const value = parseFloat(e.target.value);
      currentTime = (value / 1000) * totalDuration;
      lastTimestamp = null;
      updateAnimation(currentTime);
    };

    async function animate(timestamp) {
      if (!isPlaying) return;
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;
      lastTimestamp = timestamp;
      currentTime += delta;

      if (currentTime > totalDuration) {
        currentTime = totalDuration;
        isPlaying = false;
        playBtn.textContent = "▶";

        // start video processing
        if (${downloadVideo}) {
          console.log(new Date(), 'start video processing');
          fetch('${BACKEND}/icb-playbook/createvideo/${playNid}', {
            credentials: 'include',
            headers: {
              "Content-Type": "application/vnd.api+json",
              "Accept": "application/vnd.api+json",
            },
          })
            .then((resp) => resp.json())
            .then((data) => {
              console.log(new Date(), 'start video processing - done');
              console.log(new Date(), 'start video processing - done', JSON.stringify(data));
              const file = '${BACKEND}/' + data.file;
              alert(file);
              // TPS - I DONT KNOW HOW TO CREATE A DOWNLOAD LINK!
              // // open download
              // // Create a temporary anchor element
              // const link = document.createElement('a');
              // // link.href = '${BACKEND}/sites/default/files/icb_play_video_download/963/14744/frame000009.svg'; // Replace with your SVG file path
              // link.href = data.file;
              // link.download = 'CustomName.svg'; // Optional: specify a filename
              // // Append the anchor to the body
              // document.body.appendChild(link);
              // // Trigger a click on the anchor to start the download
              // link.click();
              // // Remove the anchor from the document
              // document.body.removeChild(link);
              // console.log(new Date(), 'download link creation and removal completed');
            })
        }
      }
      updateAnimation(currentTime);

      if (${downloadVideo} && ((currentTime / 25) > videoCurrentFrame)) { //} && (videoCurrentFrame < 40)) {
        if (videoCurrentFrame === 0) {
          const fullHTML = svgRoot.outerHTML; // e.g., '<div id="myElement" class="example">Content</div>'
          const match = fullHTML.match(/^<[^>]+>/);
          if (match) {
            //console.log(match[0]); // Logs the opening tag, e.g., '<div id="myElement" class="example">'
            postFrame("openingTag", match);
          }
          postFrame("courtSVG", courtSVG.outerHTML);
        }
        postFrame("playersSVGlinesSVG" + ('000000' + videoCurrentFrame.toString()).slice(-6), playersSVGlinesSVG.outerHTML)
        videoCurrentFrame = videoCurrentFrame + 1;
      }

      if (isPlaying) requestAnimationFrame(animate);
    }

    function updateAnimation(time) {
      time = Math.min(time, totalDuration);
      if (totalDuration <= 0) return; // no lines or something

      progressBar.value = (time / totalDuration) * 1000;

      // find current frame
      let frameIndex = cumulativeFrameDurations.findIndex(dur => time < dur);
      if (frameIndex === -1) frameIndex = framesMeta.length - 1;

      if (frameIndex !== prevFrameIndex) {
        resetAll();
        prevFrameIndex = frameIndex;
      }

      // how far into this frame
      const frameStart = frameIndex === 0 ? 0 : cumulativeFrameDurations[frameIndex - 1];
      const timeInFrame = time - frameStart;

      const fm = framesMeta[frameIndex];
      if (!fm) return;
      
      const { frame, groups, frameTimeMs } = fm;

      // Show players at their final positions for this frame
      frame.players.forEach(p => {
        const pe = players[p.id];
        if (pe) {
          pe.style.display = "";
          setPlayerPos(pe, p.x, p.y);
          toggleBall(pe, p.hasBall);
        }
      });

      // go group by group
      let groupStartMs = 0;
      for (let gIdx = 0; gIdx < groups.length; gIdx++) {
        const g = groups[gIdx];
        const groupEndMs = groupStartMs + g.groupTimeMs;
        let localTime = timeInFrame - groupStartMs;

        if (localTime < 0) localTime = 0;
        if (localTime > g.groupTimeMs) localTime = g.groupTimeMs;

        // 3 phases in group: 
        //   1) Draw (0..drawTimeMs)
        //   2) Marker (drawTimeMs..drawTimeMs+markerTimeMs)
        //   3) Movement (after that)
        const { drawTimeMs, markerTimeMs, moveTimeMs } = g;
        const phase2Start = drawTimeMs;
        const phase2End   = drawTimeMs + markerTimeMs;

        g.lines.forEach(ln => {
          const lineEl = lines[ln.id];
          if (!lineEl) return;

          setPathD(lineEl, ln, frame.players);

          if (localTime <= phase2Start) {
            // Phase 1: partial or full draw
            const partialMs = localTime; 
            drawLineSegment(lineEl, ln, partialMs, drawTimeMs);
            removeMovingElement(ln.id);
          } 
          else if (localTime <= phase2End) {
            // Phase 2: fully drawn, arrow/bar
            fullyDrawLine(lineEl, ln);
            removeMovingElement(ln.id);
          }
          else {
            // Phase 3: hide lines + movement
            lineEl.style.display = "none";
            removeEndElement(ln.id);

            // if it's dashed & fromP has a ball => remove that player's circle
            const fromP = frame.players.find(p => p.id === ln.fromId);
            if (ln.type === "dashed" && fromP && fromP.hasBall) {
              removePlayerBall(fromP.id);
            }
            
            // animate movement
            const timeIntoPhase3 = localTime - phase2End;
            const phase3Length = moveTimeMs; 
            const moveFrac = phase3Length > 0 ? (timeIntoPhase3 / phase3Length) : 1;
            animateAlongPath(lineEl, ln, moveFrac, fromP);
          }
        });

        groupStartMs = groupEndMs;
      }
    }

    // ---------------------- measureAndComputeTimings ----------------------
    function measureAndComputeTimings() {
      totalDuration = 0;
      cumulativeFrameDurations = [];

      for (let fIdx = 0; fIdx < framesMeta.length; fIdx++) {
        const fm = framesMeta[fIdx];
        let frameTime = 0;

        fm.groups.forEach(g => {
          let maxLen = 0;
          // measure each line
          g.lines.forEach(ln => {
            const lineEl = lines[ln.id];
            if (!lineEl) return;

            setPathD(lineEl, ln, fm.frame.players);
            const length = lineEl.getTotalLength();
            if (length > maxLen) maxLen = length;
          });

          // Now each phase depends on maxLen (except marker delay is fixed by seconds)
          const drawTimeMs = maxLen / DRAW_SPEED_PX_PER_MS;
          const markerTimeMs = MARKER_DELAY_SECONDS * 1000; // a fixed # of seconds
          const moveTimeMs   = maxLen / MOVEMENT_SPEED_PX_PER_MS;
          const groupTimeMs  = drawTimeMs + markerTimeMs + moveTimeMs;

          g.drawTimeMs = drawTimeMs;
          g.markerTimeMs = markerTimeMs;
          g.moveTimeMs = moveTimeMs;
          g.groupTimeMs = groupTimeMs;

          frameTime += groupTimeMs;
        });

        fm.frameTimeMs = frameTime;
        totalDuration += frameTime;
        cumulativeFrameDurations.push(totalDuration);
      }
    }

    // ---------------------- drawing phases ----------------------
    function drawLineSegment(lineEl, ln, partialMs, totalDrawMs) {
      lineEl.style.display = "";
      const length = lineEl.getTotalLength();
      // fraction of the line
      const frac = totalDrawMs > 0 ? partialMs / totalDrawMs : 1;
      const pxDrawn = length * frac;
      if (pxDrawn <= 0) {
        lineEl.setAttribute("stroke-dasharray", length);
        lineEl.setAttribute("stroke-dashoffset", length);
        removeEndElement(ln.id);
      } else if (pxDrawn >= length) {
        fullyDrawLine(lineEl, ln);
      } else {
        lineEl.setAttribute("stroke-dasharray", length);
        lineEl.setAttribute("stroke-dashoffset", length - pxDrawn);
        removeEndElement(ln.id);
      }
    }

    function fullyDrawLine(lineEl, ln) {
      lineEl.style.display = "";
      const length = lineEl.getTotalLength();
      lineEl.setAttribute("stroke-dasharray", length);
      lineEl.setAttribute("stroke-dashoffset", 0);
      // if dashed
      if (ln.type === "dashed") {
        lineEl.setAttribute("stroke-dasharray", "5,5");
      }
      // bar or arrow
      ensureEndElement(ln, lineEl);
    }

    function animateAlongPath(pathEl, ln, fraction, fromP) {
      const offsetD = pathEl.getAttribute("data-offset-d");
      const fullD   = pathEl.getAttribute("data-full-d");
      if (!fullD) return;

      // Temporarily measure with fullD
      pathEl.setAttribute("d", fullD);
      const len = pathEl.getTotalLength();
      const point = pathEl.getPointAtLength(len * fraction);
      // restore offset
      pathEl.setAttribute("d", offsetD || fullD);

      if (ln.type === "dashed" && fromP && fromP.hasBall) {
        // animate a ball
        if (!movingElements[ln.id]) {
          const ball = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          ball.setAttribute("r","15");
          ball.setAttribute("stroke","black");
          ball.setAttribute("stroke-width","2");
          ball.setAttribute("fill","none");
          svgRoot.appendChild(ball);
          movingElements[ln.id] = ball;
        }
        movingElements[ln.id].setAttribute("cx", point.x);
        movingElements[ln.id].setAttribute("cy", point.y);
      } else if (fromP) {
        // move the player
        const playerEl = players[fromP.id];
        if (playerEl) {
          setPlayerPos(playerEl, point.x, point.y);
        }
      }
    }

    // ---------------------- setPathD (offset based on control point) ----------------------
    function setPathD(lineEl, ln, framePlayers) {
      const pMap = {};
      framePlayers.forEach(p => { pMap[p.id] = p; });

      const fromP = pMap[ln.fromId];
      if (!fromP) return;

      const fromFull = { x: fromP.x, y: fromP.y };
      const toFull   = { x: ln.toX,  y: ln.toY };
      const controlPoint = { x: ln.controlX, y: ln.controlY };
      const OFFSET_RADIUS = 15; // Base radius

      // Determine offsets based on line type
      let fromOffset = OFFSET_RADIUS;
      let toOffset = OFFSET_RADIUS;

      if (ln.type === "bar" || ln.type === "straight") {
        fromOffset = 10; // Shorter offset for 'bar' and 'straight' lines
      }

      if (ln.type === "dashed") {
        toOffset = 28; // Larger offset for dashed lines to prevent overlap
      }

      // Calculate offsets based on the control point
      const startOffset = getOffsetPoint(
        fromFull.x,
        fromFull.y,
        controlPoint.x,
        controlPoint.y,
        fromOffset
      );
      
      const endOffset = getOffsetPoint(
        toFull.x,
        toFull.y,
        controlPoint.x,
        controlPoint.y,
        toOffset
      );

      let dValueFull = "";
      let dValueOffset = "";

      if (ln.type === "zigzag") {
        dValueOffset = createZigzagPath(
          startOffset, 
          controlPoint, 
          endOffset
        );
        dValueFull = \`M \${fromFull.x} \${fromFull.y} Q \${Math.round(controlPoint.x)} \${Math.round(controlPoint.y)} \${Math.round(toFull.x)} \${Math.round(toFull.y)}\`;
      } else {
        dValueFull   = \`M \${Math.round(fromFull.x)} \${Math.round(fromFull.y)} Q \${Math.round(controlPoint.x)} \${Math.round(controlPoint.y)} \${Math.round(toFull.x)} \${Math.round(toFull.y)}\`;
        dValueOffset = \`M \${Math.round(startOffset.x)} \${Math.round(startOffset.y)} Q \${Math.round(controlPoint.x)} \${Math.round(controlPoint.y)} \${Math.round(endOffset.x)} \${Math.round(endOffset.y)}\`;
      }

      lineEl.setAttribute("d", dValueOffset);
      lineEl.setAttribute("data-full-d", dValueFull);
      lineEl.setAttribute("data-offset-d", dValueOffset);
    }

    // ---------------------- Zigzag Helper ----------------------
    function approximateBezierLength(from, ctrl, to) {
      let length = 0, steps = 10, prev = from;
      for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2*to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2*to.y;
        length += Math.hypot(x - prev.x, y - prev.y);
        prev = { x, y };
      }
      return length;
    }

    function createZigzagPath(from, ctrl, to, amp=3, oscPer100=12, finSeg=10) {
      const length = approximateBezierLength(from, ctrl, to);
      const totalOsc = Math.max(2, Math.round((length / 100) * oscPer100));
      const steps = 1000, dt = 1 / steps, path = [];
      let prevX = from.x, prevY = from.y, totalLen = 0;
      const cumsum = [0];

      path.push(\`M \${Math.round(from.x)} \${Math.round(from.y)}\`);

      for (let i=1; i<=steps; i++) {
        const t = i * dt;
        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2 * to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2 * to.y;
        totalLen += Math.hypot(x - prevX, y - prevY);
        cumsum.push(totalLen);
        prevX = x;
        prevY = y;
      }

      prevX = from.x;
      prevY = from.y;
      for (let i=1; i<=steps; i++) {
        const s = (i/steps)*totalLen;
        let j=0; 
        while(j<cumsum.length && cumsum[j]<s) j++;
        if(!j) j=1;
        const s0=cumsum[j-1], s1=cumsum[j], t0=(j-1)*dt, t1=j*dt;
        const t = t0 + ((s - s0)/(s1 - s0))*(t1 - t0);

        const x = (1 - t)**2 * from.x + 2*(1 - t)*t*ctrl.x + t**2*to.x;
        const y = (1 - t)**2 * from.y + 2*(1 - t)*t*ctrl.y + t**2*to.y;
        const dxdt=2*(1 - t)*(ctrl.x - from.x)+2*t*(to.x - ctrl.x);
        const dydt=2*(1 - t)*(ctrl.y - from.y)+2*t*(to.y - ctrl.y);
        const segLen = Math.hypot(dxdt, dydt) || 1;
        const nx = -dydt/segLen, ny = dxdt/segLen;
        const freq = (totalOsc*Math.PI)/totalLen;
        const off = amp*Math.sin(s*freq);

        path.push(\`L \${Math.round(x + off*nx)} \${Math.round(y + off*ny)}\`);
      }

      const theta = Math.atan2(to.y - ctrl.y, to.x - ctrl.x);
      path.push(\`L \${Math.round(to.x + finSeg*Math.cos(theta))} \${Math.round(to.y + finSeg*Math.sin(theta))}\`);

      return path.join(' ');
    }

    // ---------------------- Helpers ----------------------
    function getOffsetPoint(x1, y1, x2, y2, offset) {
      const dx = x2 - x1, dy = y2 - y1;
      const dist = Math.hypot(dx, dy);
      if (!dist) return { x: x1, y: y1 };
      return {
        x: x1 + (dx * offset / dist),
        y: y1 + (dy * offset / dist),
      };
    }

    function setPlayerPos(playerEl, x, y) {
      const txt = playerEl.querySelector("text");
      if (txt) {
        txt.setAttribute("x", x);
        txt.setAttribute("y", y);
      }
      const c = playerEl.querySelector('circle[r="15"]');
      if (c) {
        c.setAttribute("cx", x);
        c.setAttribute("cy", y);
      }
    }

    function toggleBall(playerEl, hasBall) {
      const existing = playerEl.querySelector('circle[r="15"]');
      if (hasBall && !existing) {
        const c = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        const txt = playerEl.querySelector("text");
        c.setAttribute("cx", txt?.getAttribute("x") || "0");
        c.setAttribute("cy", txt?.getAttribute("y") || "0");
        c.setAttribute("r", "15");
        c.setAttribute("stroke", "black");
        c.setAttribute("stroke-width", "2");
        c.setAttribute("fill", "none");
        playerEl.insertBefore(c, txt);
      } else if (!hasBall && existing) {
        playerEl.removeChild(existing);
      }
    }

    function removePlayerBall(playerId) {
      const pe = players[playerId];
      if (!pe) return;
      const ballCircle = pe.querySelector('circle[r="15"]');
      if (ballCircle) pe.removeChild(ballCircle);
    }

    function ensureEndElement(ln, lineEl) {
      if (!endElements[ln.id]) {
        if (ln.type === "bar") {
          endElements[ln.id] = createBarEl(lineEl, "black");
        } else {
          endElements[ln.id] = createArrowEl(lineEl, "black");
        }
        if (endElements[ln.id]) svgRoot.appendChild(endElements[ln.id]);
      }
    }

    function removeEndElement(lineId) {
      if (endElements[lineId]) {
        endElements[lineId].remove?.();
        delete endElements[lineId];
      }
    }
      
    function removeMovingElement(lineId) {
      if (movingElements[lineId]) {
        movingElements[lineId].remove?.();
        delete movingElements[lineId];
      }
    }

    // create arrow
    function createArrowEl(lineEl, color) {
      const len = lineEl.getTotalLength();
      if (len < 2) return null;
      const endPoint = lineEl.getPointAtLength(len);
      const startPoint = lineEl.getPointAtLength(Math.max(len - 10, 0));
      const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

      const arrowLength = 18;
      const arrowWidth = Math.PI / 9;
      const baseHeight = -5;
      const offsetLength = -12;

      const adjustedEndPoint = {
        x: endPoint.x - offsetLength * Math.cos(angle),
        y: endPoint.y - offsetLength * Math.sin(angle),
      };

      const arrowPoint1 = {
        x: adjustedEndPoint.x - arrowLength * Math.cos(angle - arrowWidth),
        y: adjustedEndPoint.y - arrowLength * Math.sin(angle - arrowWidth),
      };
      const arrowPoint2 = {
        x: adjustedEndPoint.x - arrowLength * Math.cos(angle + arrowWidth),
        y: adjustedEndPoint.y - arrowLength * Math.sin(angle + arrowWidth),
      };

      const baseMiddle = {
        x: (arrowPoint1.x + arrowPoint2.x) / 2 - baseHeight * Math.cos(angle),
        y: (arrowPoint1.y + arrowPoint2.y) / 2 - baseHeight * Math.sin(angle),
      };

      const arrowPath = \`M \${adjustedEndPoint.x} \${adjustedEndPoint.y}
                         L \${arrowPoint1.x} \${arrowPoint1.y}
                         L \${baseMiddle.x} \${baseMiddle.y}
                         L \${arrowPoint2.x} \${arrowPoint2.y} Z\`;

      const pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathEl.setAttribute("d", arrowPath);
      pathEl.setAttribute("stroke", color);
      pathEl.setAttribute("stroke-width", "2");
      pathEl.setAttribute("fill", color);
      return pathEl;
    }

    // create bar
    function createBarEl(lineEl, color, barWidth = 30, barLength = 40, offsetLength = 20) {
      const len = lineEl.getTotalLength();
      if (len < 2) return null;
      const endPoint = lineEl.getPointAtLength(len);
      const startPoint = lineEl.getPointAtLength(Math.max(len - 10, 0));
      const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);

      const adjustedEndPoint = {
        x: endPoint.x + offsetLength * Math.cos(angle),
        y: endPoint.y + offsetLength * Math.sin(angle),
      };

      const barCenter = {
        x: adjustedEndPoint.x - (barLength / 2) * Math.cos(angle),
        y: adjustedEndPoint.y - (barLength / 2) * Math.sin(angle),
      };

      const barEnd1 = {
        x: barCenter.x + (barWidth / 2) * Math.cos(angle + Math.PI / 2),
        y: barCenter.y + (barWidth / 2) * Math.sin(angle + Math.PI / 2),
      };
      const barEnd2 = {
        x: barCenter.x - (barWidth / 2) * Math.cos(angle + Math.PI / 2),
        y: barCenter.y - (barWidth / 2) * Math.sin(angle + Math.PI / 2),
      };

      const barPath = \`M \${barEnd1.x} \${barEnd1.y} L \${barEnd2.x} \${barEnd2.y}\`;
      const pathEl = document.createElementNS("http://www.w3.org/2000/svg", "path");
      pathEl.setAttribute("d", barPath);
      pathEl.setAttribute("stroke", color);
      pathEl.setAttribute("stroke-width", "2");
      pathEl.setAttribute("fill", "none");
      return pathEl;
    }

    // ---------------------- resetAll ----------------------
    function resetAll() {
      Object.values(players).forEach(pe => {
        pe.style.display = "none";
      });
      Object.values(lines).forEach(le => {
        le.style.display = "none";
        le.removeAttribute("stroke-dasharray");
        le.removeAttribute("stroke-dashoffset");
      });
      Object.values(movingElements).forEach(e => e?.remove?.());
      for (let k in movingElements) delete movingElements[k];
      Object.values(endElements).forEach(el => el?.remove?.());
      for (let k in endElements) delete endElements[k];
    }
`;

  return script;
};

// -------------- 3) FINAL HTML GENERATION --------------
export default function PlayAnimationHTMLDocConOrden(frames: Frame[], courtType: string, playNid: number, downloadVideo: boolean = false) {
  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8" />
  <title>SVG Animation</title>
  <style>
    body {
      margin: 0;
      padding: 0;
      font-family: sans-serif;
    }
    #controls {
      text-align: center;
      margin: 10px 0;
    }
    #play-pause {
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;
      background-color: #fff;
      color: #333;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
    }
    #play-pause:hover {
      background-color: #f0f0f0;
    }
    #progress-bar {
      width: 90%;
      margin-top: 6px;
    }
    svg {
      display: block;
      margin: 0 auto;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  </style>
</head>
<body>
  ${generateSVGContent(frames, courtType)}

  <div id="controls">
    <button id="play-pause">▶</button>
    <input type="range" id="progress-bar" min="0" max="1000" value="0" />
  </div>

  <script>
    ${generateAnimationScript(frames, playNid, downloadVideo)}
  </script>

</body>
</html>
  `;
}
