import { useContext, useEffect, useState } from 'react';
import { Box, Divider, Drawer, IconButton, styled, Tooltip, Typography, useTheme } from '@mui/material';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionShowPracticeProgram, OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import { DRUPALENTITYINIT, ICB_USER_ID } from '../misc/Constants';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { getConfigValue, getImageURL, logout } from '../misc/Functions';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { AccountCircleRounded, Groups2 } from '@mui/icons-material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { ListItemButton } from '@mui/material';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DialogTeamPlanStepper from './DialogTeamPlanStepper';


const drawerWidth = 225;
const collapsedDrawerWidth = 57;

const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
}));

export default function DrawerLandscape() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [manualToggle, setManualToggle] = useState(false);
  const [selectedPath, setSelectedPath] = useState<string | null>(location.pathname);
  const [openDialogTeamPlanStepper, setOpenDialogTeamPlanStepper] = useState(false);

  const { state, dispatch } = useContext(Context) as TypeContext;

  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  const handleDrawerOpen = () => {
    if (!manualToggle) setOpen(true);
  };

  const handleDrawerClose = () => {
    if (!manualToggle) setOpen(false);
  };

  const toggleDrawer = () => {
    setManualToggle(true);
    setOpen(!open);
    setTimeout(() => setManualToggle(false), 300);
  };

  /** Helpers **/

  const isSelected = (path: string) => selectedPath === path;

  const handleSelectPath = (pathOrFn: string | (() => void)) => {
    if (typeof pathOrFn === 'function') pathOrFn();
    else navigate(pathOrFn);
  };

  // Helper to render each menu item (with optional "create" button)
  /** RENDER HELPERS **/
  const renderMenuItem = (item: any) => {
    if (!item.isVisible) return null;

    const selected = isSelected(item.path);
    const activeIconColor = item.createButtonColor || theme.palette.secondary.main;

    return (
      <Box
        key={item.text}
        paddingX={1}
        paddingBottom={0.5}
        display="flex"
        alignItems="center"
      >
        <ListItemButton
          onClick={() => handleSelectPath(item.action0 || item.action)}
          selected={selected}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: open ? 'flex-start' : 'center',
            borderRadius: 2,
            width: '100%',
            padding: '8px',
          }}
        >
          {selected
            ? item.selectedIcon ?? item.icon
            : item.icon
          }
          {open && (
            <Typography
              sx={{
                fontSize: 14,
                whiteSpace: 'nowrap',
                ml: 1,
                fontWeight: selected ? 'bold' : 'normal',
                color: item.textPermanentColor || (selected ? activeIconColor : ''),
              }}
            >
              {t(item.text)}
            </Typography>
          )}
        </ListItemButton>

        {/* Botón de creación opcional */}
        {open && item.action1 && (
          <IconButton aria-label="create" sx={{ ml: 1 }}>
            <Tooltip title={t(item.tooltipCreate)} placement="right">
              <AddBoxRoundedIcon
                onClick={item.action1}
                sx={{ color: activeIconColor }}
              />
            </Tooltip>
          </IconButton>
        )}
      </Box>
    );
  };

  const menuSections = [
    {
      divider: false,
      items: [
        {
          text: 'DrawerLandscape00',
          icon: <HomeOutlinedIcon />,
          selectedIcon: <HomeRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
          action: '/home',
          path: '/home',
          isVisible: !state.portrait,
        },
      ],
    },
    {
      divider: true,
      items: [
        {
          text: 'DrawerLandscape01',
          icon: <SportsBasketballOutlinedIcon />,
          selectedIcon: <SportsBasketballRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/icbdrills',
          action0: () => {
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
            navigate('/icbdrills');
          },
          action1: () => {
            // dont change operation mode. After create exercise we navigate(-1) and we want to keep group and state, ie group X and create practice, ie group Y and create preplanned practice
            // dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
            navigate('/exercisecreate');
          },
          tooltipCreate: 'DrawerLandscape04',
          createButtonColor: theme.palette.secondary.main,
          isVisible: true,
        },
        {
          text: 'DrawerLandscape02',
          icon: <FolderOpenOutlinedIcon />,
          selectedIcon: <FolderRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/practices',
          action0: '/practices',
          action1: () => {
            navigate('/icbdrills');
            dispatch({ type: 'showPracticeProgram', show: true });
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
          },
          tooltipCreate: 'Exercises01',
          createButtonColor: theme.palette.secondary.main,
          isVisible: true,
        },
        {
          text: 'DrawerLandscape14',
          icon: (
            <img
              src={
                theme.palette.mode === 'dark'
                  ? '/images/playbuilder/PlaysIconWhite.svg'
                  : '/images/playbuilder/PlaysIcon.svg'
              }
              alt="Plays"
              style={{ width: 24, height: 24 }}
            />
          ),
          selectedIcon: (
            <img
              src={
                theme.palette.mode === 'dark'
                  ? '/images/playbuilder/PlaysIconWhite.svg'
                  : '/images/playbuilder/PlaysIconBlue.svg'
              }
              alt="Plays"
              style={{ width: 24, height: 24 }}
            />
          ),
          path: '/icbplays',
          action0: () => {
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
            navigate('/icbplays');
          },
          action1: () => {
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
            dispatch({ type: 'setPlay', play: DRUPALENTITYINIT });
            navigate('/createplay');
          },
          tooltipCreate: 'ICBAppBar13',
          createButtonColor: theme.palette.primary.main,
          isVisible: true,
        },
        {
          text: 'DrawerLandscape15',
          icon: <ArticleOutlinedIcon />,
          selectedIcon: <ArticleIcon sx={{ color: theme.palette.primary.main }} />,
          path: '/myplaybooks',
          action0: '/myplaybooks',
          action1: () => {
            // dispatch({ type: 'setPlaybook', playbook: DRUPALENTITYINIT });
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
            dispatch({ type: 'showPracticeProgram', show: true });
            navigate('/icbplays');
          },
          tooltipCreate: 'ICBAppBar14',
          createButtonColor: theme.palette.primary.main,
          isVisible: true,
        },
      ],
    },
    {
      divider: (getConfigValue(state, 'enablePreplannedPractices') || state.user.data.id === ICB_USER_ID),
      items: [
        {
          text: 'DrawerLandscape19',
          icon: <NextPlanOutlinedIcon />,
          selectedIcon: <NextPlanIcon sx={{ color: theme.palette.mode === 'light' ? '#3095ca' : 'white' }} />,
          path: '/preplannedpractices',
          action0: () => {
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.preplannedPractice });
            navigate('/preplannedpractices');
          },
          action1: () => {
            dispatch({ type: 'setOperationMode', operationMode: OperationMode.preplannedPractice });
            const actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true }
            dispatch(actionShowPracticeProgram);
            navigate('/icbpreplannedpractices')
          },
          createButtonColor: theme.palette.mode === 'light' ? '#3095ca' : 'white',
          isVisible: (getConfigValue(state, 'enablePreplannedPractices') || state.user.data.id === ICB_USER_ID)
            && (state.user.data.attributes.field_user_type === 'clubadmin' || state.user.data.id === ICB_USER_ID),
        },
      ],
    },
    {
      divider: false,
      items: [
        {
          text: 'DrawerLandscape18',
          textPermanentColor: '#DD6F20',
          icon: <AutoFixHighOutlinedIcon sx={{ color: '#DD6F20' }} />,
          action: () => setOpenDialogTeamPlanStepper(true),
          isVisible: (getConfigValue(state, 'enablePreplannedPractices') || state.user.data.id === ICB_USER_ID)
        },
      ],
    },
    {
      divider: true,
      items: [
        {
          text: 'DrawerLandscape13',
          icon: <LightbulbOutlinedIcon />,
          selectedIcon: <LightbulbIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/concepts',
          action: '/concepts',
          isVisible: true,
        },
        {
          text: 'DrawerLandscape03',
          icon: <InsertChartOutlinedIcon />,
          selectedIcon: <InsertChartIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/formgetpracticereport',
          action: '/formgetpracticereport',
          isVisible: true,
        },
        {
          text: 'DrawerLandscape11',
          icon: <ArticleOutlinedIcon />,
          selectedIcon: <ArticleIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/clubdocuments',
          action: '/clubdocuments',
          isVisible: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type),
        },
        {
          text: 'DrawerLandscape05',
          icon: <SortByAlphaRoundedIcon />,
          selectedIcon: <SortByAlphaRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/groups',
          action: '/groups',
          isVisible: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type),
        },
        {
          text: 'DrawerLandscape10',
          icon: <GroupWorkOutlinedIcon />,
          selectedIcon: <GroupWorkIcon sx={{ color: theme.palette.secondary.main }} />,
          path: '/myteams',
          action: '/myteams',
          isVisible: true,
        },
        {
          text: 'DrawerLandscape06',
          icon: <GroupsOutlinedIcon />,
          selectedIcon: <Groups2 sx={{ color: theme.palette.secondary.main }} />,
          path: '/coaches',
          action: '/coaches',
          isVisible: state.user.data.attributes.field_user_type === 'clubadmin',
        },
      ],
    },
  ];

  /** Render **/

  return (
    <>
      <CustomDrawer
        variant="permanent"
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        open={open}
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {/* Drawer header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 1,
            ...theme.mixins.toolbar,
          }}
        >
          {open && (
            <Box mt="7px" ml="10px">
              <img
                height={32}
                src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')}
                alt="Logo"
              />
            </Box>
          )}
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>

        {/* Main sections */}
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {menuSections.map((section, idx) => (
            <Box key={idx}>
              {section.divider && <Divider sx={{ mb: 0.5, mx: 1 }} />}
              {section.items.map(renderMenuItem)}
            </Box>
          ))}
        </Box>

        <Divider sx={{ mb: 0.5, mx: 1 }} />

        {/* Account + Logout at bottom */}
        <Box paddingX={1} paddingBottom={1} display="flex" alignItems="center">
          <ListItemButton
            onClick={() => navigate('/profile')}
            selected={isSelected('/profile')}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: open ? 'flex-start' : 'center',
              borderRadius: 2,
              width: '100%',
              p: '8px',
            }}
          >
            {isSelected('/profile') ? (
              <AccountCircleRounded sx={{ color: theme.palette.secondary.main }} />
            ) : (
              <AccountCircleRounded />
            )}
            {open && (
              <Typography
                sx={{
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                  ml: 1,
                  fontWeight: isSelected('/profile') ? 'bold' : 'normal',
                  color: isSelected('/profile') ? theme.palette.secondary.main : '',
                }}
              >
                {t('DrawerLandscape07')}
              </Typography>
            )}
          </ListItemButton>
          {open && (
            <IconButton onClick={() => logout(state, dispatch, navigate)} aria-label="logout">
              <Tooltip title={t('DrawerLandscape16')} placement="right">
                <LogoutRoundedIcon />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      </CustomDrawer>

      {/* Practice Generator Dialog */}
      <DialogTeamPlanStepper
        open={openDialogTeamPlanStepper}
        onClose={() => setOpenDialogTeamPlanStepper(false)}
      />
    </>
  );
}
