import { Fragment, useContext, useEffect, useState } from "react";
import { AccountAction, ActionSaveOnGlobalState, ActionSetConfiguration, ActionSetLogin, EnvironmentType, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Button, Checkbox, FormControlLabel, Grid, Link, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import {
    testEnvironmentIndication, getHeaders, getActionSetConfirm, getImageURL, getEnvironment, landingPage,
    getDD, reloadApp, fetchData, formatLanguage, validateEmail, icbControllerGenerel02
} from "../misc/Functions";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import log from "../misc/Logger";
import { BACKEND, JSONAPIPATH, } from "../misc/Constants";
import { useTranslation } from "react-i18next";
import { MuiTelInput } from "mui-tel-input";
import { useCookies } from "react-cookie";
import prefix from 'loglevel-plugin-prefix'; // logging

// Fields from form that are passed to backend controller
interface TypeAccount {
    name: string,
    mail: string,
    pass: string,
    pass_repeat: string,
    reCaptcha: string,
    field_first_name: string,
    field_last_name: string,
    field_phone_number: string,
    preferred_langcode: string,
    acceptTermsAndConditions: boolean,
    field_email_ver_code: string,
    // return messages to the user
    mail_sent: string,
    user_created: string,
    password_mismatch: string,
    username_taken: string,
    email_taken: string,
    email_subject: string,
    email_body: string,
    email_verification_code_invalid: string,
    email_verification_resent: string,
}

interface PropsAccountCaptcha {
    mode: AccountAction,                    // 'login', 'register' or 'getnewpassword'
    showEmailVerificationCode: boolean,     // show field to enter email verification code initially              
}

export default function AccountCaptcha(props: PropsAccountCaptcha) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    log.debug('AccountCaptcha');

    const [mode, setMode] = useState(props.mode)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showEmailVerificationCode, setShowEmailVerificationCode] = useState(props.showEmailVerificationCode)
    const [signUpProcess, setSignUpProcess] = useState(props.showEmailVerificationCode)
    const [_cookie, setCookie] = useCookies(['icoachbasketball']); // prefix cookies with '_' to avoid compile error

    // Go to signup if spedified on query string
    let location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');
    useEffect(() => {
        if (query === 'signup') {
            setMode(AccountAction.signup);
        }
    }, [])

    // If App tries to render AccountCaptcha and user is already logged on then render Home
    useEffect(() => {
        if (state.loggedIn === 1) {
            if (state.clubInvite)
                navigate('/setsubscription')
            else
                navigate('/home')
        }
    }, [state.loggedIn])

    const [disabled, setDisabled] = useState(false);

    // Code to reset captcha. See https://stackoverflow.com/questions/46514194/how-to-reset-google-recaptcha-with-react-google-recaptcha
    let captcha: any;
    const setCaptchaRef = (ref: any) => {
        if (ref) {
            return captcha = ref;
        }
    };

    // Protect against maintenance mode where we can't get configuration
    const user_created = state.configuration.length > 0 && state.configuration[0].attributes.field_email_ver_code_active ? t("AccountCaptcha17A") : t("AccountCaptcha17")

    // Data in form
    let accountObj: TypeAccount = {
        // For easy development:
        // name: 'c20',
        // mail: 'c20@netmaster.dk',
        // pass: 'test',
        // pass_repeat: 'test',
        // reCaptcha: '',
        // field_first_name: 'FN',
        // field_last_name: 'LN',
        // field_phone_number: '',
        // acceptTermsAndConditions: true,
        // preferred_langcode: 'enUS',

        // For test and production:
        name: '',
        mail: '',
        pass: '',
        pass_repeat: '',
        reCaptcha: '',
        field_first_name: '',
        field_last_name: '',
        field_phone_number: '',
        acceptTermsAndConditions: false,
        preferred_langcode: '----',

        field_email_ver_code: '',

        // return messages to the user
        mail_sent: t("AccountCaptcha12"),
        user_created: user_created,
        password_mismatch: t("AccountCaptcha18"),
        username_taken: t("AccountCaptcha19"),
        email_taken: t("AccountCaptcha20"),
        email_subject: t("AccountCaptcha22"),
        email_body: t("AccountCaptcha23"),
        email_verification_code_invalid: t("AccountCaptcha24"),
        email_verification_resent: t("AccountCaptcha26"),
    }

    // State of data in form
    const [account, setAccount] = useState<TypeAccount>(state.obj || accountObj)

    // Needed when user changed language in signup page so we get correct message to the backend when user presses signup button
    useEffect(() => {
        setAccount({
            ...account,
            // return messages to the user
            mail_sent: t("AccountCaptcha12"),
            user_created: user_created,
            password_mismatch: t("AccountCaptcha18"),
            username_taken: t("AccountCaptcha19"),
            email_taken: t("AccountCaptcha20"),
            email_subject: t("AccountCaptcha22"),
            email_body: t("AccountCaptcha23"),
            email_verification_resent: t("AccountCaptcha26"),
        })
    }, [i18n.language])

    // Form fields. Fields are shown depending on action
    const fields = [
        {
            id: 'name',
            label: t("AccountCaptcha00"),
            type: 'text',
            selectinputtype: false,
            show: [AccountAction.login, AccountAction.signup].includes(mode),
        },
        {
            id: 'mail',
            label: t("AccountCaptcha01"),
            type: 'email',
            selectinputtype: false,
            show: [AccountAction.signup, AccountAction.getnewpassword, AccountAction.resendemailverificationcode].includes(mode),
        },
        {
            id: 'field_first_name',
            label: t("AccountCaptcha02"),
            type: 'text',
            selectinputtype: false,
            show: [AccountAction.signup].includes(mode),
        },
        {
            id: 'field_last_name',
            label: t("AccountCaptcha03"),
            type: 'text',
            selectinputtype: false,
            show: [AccountAction.signup].includes(mode),
        },
        // {
        //     id: 'field_phone_number',
        //     label: t("AccountCaptcha13"),
        //     type: 'text',
        //     selectinputtype: false,
        //     show: [AccountAction.signup].includes(mode),
        // },
        {
            id: 'pass',
            label: t("AccountCaptcha04"),
            type: 'password',
            selectinputtype: false,
            show: [AccountAction.login, AccountAction.signup].includes(mode),
        },
        {
            id: 'pass_repeat',
            label: t("AccountCaptcha04"),
            type: 'password',
            selectinputtype: false,
            show: [AccountAction.signup].includes(mode),
        },
        {
            id: 'field_email_ver_code',
            label: t("AccountCaptcha21"),
            type: 'text',
            selectinputtype: false,
            show: showEmailVerificationCode, // [AccountAction.login].includes(mode),
        },
        {
            id: 'preferred_langcode',
            label: t("AccountCaptcha16"),
            type: 'text',
            selectinputtype: true,
            selectinputtypeoptions: [
                {
                    label: 'SELECT LANGUAGE',
                    value: '----',
                },
                {
                    label: 'English',
                    value: 'enUS',
                },
                {
                    label: 'Spanish',
                    value: 'esES',
                },
                {
                    label: 'Catalan',
                    value: 'caES',
                },
            ],
            // selectinputtypeaction: () => { alert('selection') },
            show: [AccountAction.signup].includes(mode),
        },
    ]

    // Form buttons
    const buttons = [
        {
            id: 'login',
            label: t("AccountCaptcha07"),
            action: AccountAction.login,
            isVisible: true,
        },
        {
            id: 'signup',
            label: t("AccountCaptcha08"),
            action: AccountAction.signup,
            isVisible: true,
        },
        {
            id: 'resendemailverificationcode',
            label: t("AccountCaptcha25"),
            action: AccountAction.resendemailverificationcode,
            isVisible: signUpProcess, // showEmailVerificationCode, // true,
        },
        {
            id: 'getnewpassword',
            label: t("AccountCaptcha09"),
            action: AccountAction.getnewpassword,
            isVisible: true,
        },
    ]

    // Handle change in form
    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        const { name, value } = event.target;
        setAccount(prevState => ({
            ...prevState,  // shallow copy all previous state values
            [name]: ['field_first_name', 'field_last_name'].includes(name) ? value : value.trim(), // update specific key/value
        }));
    };

    // Handle change in form phone number field
    const handleChangePhoneNUmber = (newValue: any) => {
        setPhoneNumber(newValue);
        setAccount(prevState => ({
            ...prevState,  // shallow copy all previous state values
            field_phone_number: newValue.trim(), // update specific key/value
        }));
    };

    // Handle change of accept terms and conditions
    const handleChangeAcceptTermsAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccount(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
    };

    // Handle click of command button to execute action
    const handleClick = (id: string) => {
        setDisabled(true); // prevent double click
        // Find action
        let action: AccountAction = buttons.find(x => x.id === id)?.action!;
        // Navigate to do another action?
        if (action !== mode) {
            setMode(action)
            setShowEmailVerificationCode(action === AccountAction.login && signUpProcess)
            setDisabled(false);
        } else {
            // Execute action if all fields filled out
            if (allFieldsFilledOut()) {
                submit(action);
            } else {
                setDisabled(false);
            }
        }
    };

    // Submit form to backend
    async function icbController() {
        // BE CAREFULL - WE LOG PASSWORDS IN CLEAR TEXT! log.info('AccountCaptcha(), icbController(): ', JSON.stringify(account))
        let URL = `${BACKEND}/icbaccount`;
        log.debug(URL)
        const resp = await fetch(URL, getHeaders(state.user.login.csrf_token, 'POST', {
            ...account,
            opr: mode,
            preferred_langcode: formatLanguage(account.preferred_langcode), // send two pos langcode and not four pos langcode
        }));
        log.debug(`${BACKEND}/icbaccount`, resp.status);
        if (resp.status === 200) {
            const json = await resp.json();
            log.debug(JSON.stringify(json, null, 3));
            if (json.ok) {
                // Show message if response includes message, ie. mail sent due to pw request
                if (json.msg) {
                    dispatch(getActionSetConfirm(json.msg));
                }
                // 
                if (json.msg === t('AccountCaptcha17A') || json.msg === t('AccountCaptcha26')) {
                    /*
                    User has signed up and received email with email verification code. Or user has requested new email verification code.
                    Open fields so user can supply email verification code
                    */
                    setShowEmailVerificationCode(true)
                    setSignUpProcess(true)
                    window.scrollTo(0, 0);
                }
                setMode(AccountAction.login);
            } else {
                if (json.errno === 1) {
                    // Invalid captcha. Set captcha to unchecked state and show message
                    captcha.reset();
                } else {
                    dispatch(getActionSetConfirm(json.msg));
                }
            }
        } else {
            const text = await resp.text();
            log.error(text);
            dispatch(getActionSetConfirm(text));
        }
        setDisabled(false); // back end has been called. Now it is safe to click the button again
    }

    // Test fields are filled out! Check captcha if needed                
    function allFieldsFilledOut(): boolean {
        // Find empty field
        let field = fields.filter(x => x.show && !['field_phone_number', 'field_email_ver_code'].includes(x.id)).find(y => account[y.id as keyof typeof account] === '')
        if (field) {
            dispatch(getActionSetConfirm(`${t('AlertMsg22')} ${field.label}`));
        } else if ([AccountAction.signup, AccountAction.getnewpassword].includes(mode) && account.reCaptcha === '' && state.configuration[0].attributes.field_show_captcha) {
            dispatch(getActionSetConfirm(t('AlertMsg18')));
        } else if (!account['acceptTermsAndConditions'] && [AccountAction.signup].includes(mode)) {
            dispatch(getActionSetConfirm(t('AlertMsg19')));
        } else if (account['preferred_langcode'] === '----' && [AccountAction.signup].includes(mode)) {
            dispatch(getActionSetConfirm(t('AlertMsg38')));
        } else if (!validateEmail(account['mail']) && [AccountAction.signup, AccountAction.resendemailverificationcode].includes(mode)) {
            dispatch(getActionSetConfirm(t('Generel20')));
        } else if (mode === AccountAction.signup && account['pass'] !== account['pass_repeat']) {
            dispatch(getActionSetConfirm(t('AccountCaptcha18')));
        } else {
            return true;
        }
        return false;
    }

    // Add this function inside your AccountCaptcha component
    const clearCacheAndRefresh = async () => {
        // Attempt to unregister service workers as a way to clear some of the caches
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
                await registration.unregister();
            }
        }
        // Force reload the page from the server
        reloadApp(state.nativeApp)
    };

    // Login to Drupal and get user info.
    async function login(newVersion: boolean) { //name: string, pass: string) {
        let URL = `${BACKEND}/user/login?_format=json`; // See https://www.drupal.org/node/2720655
        log.debug(`${URL}`);
        let resp = await fetch(URL, getHeaders('', 'POST', {
            name: account.name,
            pass: account.pass,
            // Next two fields are use by custom module icb_login
            field_email_ver_code: account.field_email_ver_code,
            email_verification_code_invalid: t("AccountCaptcha24"),
        }));
        log.debug(`${resp.status}`);
        if (resp.status === 200) {
            if (newVersion) {
                // get new version of app and keep session login so user does not have to relogin with new app version
                log.info(`${account.name} relogin to get new version`)
                clearCacheAndRefresh()
                return
            }
            const json = await resp.json();
            // Change log prefix now we know who is logged in
            prefix.apply(log, { template: `${json.current_user.name} (${json.current_user.uid})` })
            // We have new user data. Update state with new user data
            log.info(`${json.current_user.name} logged in. App version ${__APP_VERSION__}`)
            // Convert uid from string to int to enable compare with JSONAPI data
            json.current_user.uid = parseInt(json.current_user.uid)
            let action: ActionSetLogin = { type: 'setLogin', login: json }
            dispatch(action);
            // Create updated copy of state so we can pass this updated state to fetchData()
            let user = { ...state.user, login: json };
            let stateNewLoginData = { ...state, user: user };
            fetchData(stateNewLoginData, dispatch); // fetch data for just logged on user
            if (state.clubInvite)
                navigate('/setsubscription');
            else
                navigate('/home');
        } else if (resp.status === 400) {
            // Unknown password etc.
            const json = await resp.json();
            log.debug(`${json.message} user:${name}`);
            dispatch(getActionSetConfirm(json.message));
            if (json.message === t('AccountCaptcha24')) {
                // User has tried to login but email has not been verified
                setShowEmailVerificationCode(true)
                setSignUpProcess(true)
            }
        } else if (resp.status === 503) {
            // Maintenance mode
            const text = await resp.text();
            dispatch(getActionSetConfirm(text));
        } else {
            const text = await resp.text();
            log.error(text);
            dispatch(getActionSetConfirm(t('AlertMsg02')));
        }
    }

    // Handle press of submit button
    async function submit(action: AccountAction) {
        /*
        User wants to submit form. Route behind this form is only for anonymous users.
        If user already logged in then log user out. Otherwise, we get error
        "This route can only be accessed by anonymous users." User can be logged in if:
        1. open app and show login dialog in tab A in browser
        2. request new password
        3. set new password in tab B in browser
        4. go back to tab A and login with new password
        */
        // show ok! log.info('AccountCaptcha, test, get login_status - XXXXXXXXXXX');
        const respLoginStatus = await getDD(state, dispatch, `${BACKEND}/user/login_status?_format=json`);
        if (respLoginStatus === "1") {
            log.info('AccountCaptcha, test, logout');
            const respLogout = await icbControllerGenerel02(state, { "opr": "logout" })
            if (!respLogout.ok) {
                log.info('AccountCaptcha, test, logout error');
                dispatch(getActionSetConfirm(respLogout.error))
                return
            }
        }

        // show fail! why? log.info('AccountCaptcha, test, before switch');
        switch (action) {
            case AccountAction.login:
                // Get configuration in case version has been updated since user last started the app
                // show fail! why? log.info('AccountCaptcha, test, get configuration');
                const configuration = await getDD(state, dispatch, `${BACKEND}/${JSONAPIPATH}/node/configuration`);
                if (!configuration.data) {
                    // show fail! why? log.info('AccountCaptcha, test, error get configuration');
                    setDisabled(false);
                    return; // Error
                }
                let action: ActionSetConfiguration = { type: 'setConfiguration', configuration: configuration.data }
                dispatch(action);

                // // Check no diff in server version and app version
                // if (configuration.data[0].attributes.field_app_version !== __APP_VERSION__) {
                //     clearCacheAndRefresh()
                //     setDisabled(false);
                // } else {
                //     await login()
                //     setDisabled(false);
                // }

                // show fail! why? log.info('AccountCaptcha, test, await login');
                await login(configuration.data[0].attributes.field_app_version !== __APP_VERSION__)
                // show fail! why? log.info('AccountCaptcha, test, done login');
                setDisabled(false);
                // show fail! why? log.info('AccountCaptcha, test, before break');
                break;
            case AccountAction.signup:
            case AccountAction.getnewpassword:
            case AccountAction.resendemailverificationcode:
                icbController();
                break;
        }
    }

    // Get google sitekey for current host
    function getReCAPTCHASitekey() {
        switch (getEnvironment()) {
            case EnvironmentType.dev:
                return '6Lc5JiAlAAAAAJU3nks7GavFb-3m8zliGFUdWiED'
            case EnvironmentType.prod:
                return '6LcDFiAlAAAAAJBAR1Qjtqv-lWSD35vbjkkq16T0';
            case EnvironmentType.test:
                return '6LcLvyElAAAAAAKplg0GZdu9oIuvGLy9axtXtlds'
            default:
                return ''
        }
    }

    // Handle press "I am not a robot"
    function onChangeRecaptcha(value: any) {
        log.debug('Captcha value:', value);
        setAccount(prevState => ({
            ...prevState,
            ['reCaptcha']: value || '',
        }));
    }

    // Get text to show over all form input fields
    function actionHeader() {
        if (mode === AccountAction.getnewpassword) return (<>{t("AccountCaptcha09")}</>)
        if (mode === AccountAction.login) return (<>{t("AccountCaptcha07")}</>)
        if (mode === AccountAction.signup) return (<>{t("AccountCaptcha08")}</>)
    }

    return (
        // show last fields even with popup keyboard on iPhone
        <Box paddingBottom={state.portrait ? '40%' : '0%'}>
            <Grid sx={{
                position: 'fixed',
                top: 0,
                zIndex: 50,
                backgroundColor: state.portrait ? 'white' : '#00398F', // Establece el color de fondo a blanco
                width: '100%',
                boxShadow: '0 1px 2px rgba(0,0,0,0.05)' // Sombra más sutil
            }}>
                <Typography sx={{
                    fontFamily: 'PT Sans, sans-serif',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textAlign: 'center',
                    width: '100%',
                    color: state.portrait ? 'black' : 'white', // Establece el color de fondo a blanc
                    marginTop: 1,
                    marginBottom: 1,
                    padding: '8px 0' // Añade un poco de relleno para asegurar que el contenido no esté demasiado apretado
                }}>


                    {actionHeader()}
                </Typography>
            </Grid>

            <Box
                height={'101vh'}
                display={'flex'}
                sx={{
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: 10,
                    backgroundColor: 'white'
                }}>

                <div style={{ width: '95%', maxWidth: '450px' }}> {/* Eliminado overflow y height */}
                    <Grid container direction="row" justifyContent="center">
                        {/* Logo */}
                        <Tooltip title={'Home'}>
                            <Button onClick={() => {
                                if (!state.nativeApp)
                                    window.location.href = landingPage()
                            }}>
                                <img width={50} height={'auto'} src={getImageURL('logoICBBlue.webp')} style={{ borderRadius: "50%" }} />
                            </Button>
                        </Tooltip>

                        {/* Header */}
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" style={{ fontFamily: 'PT Sans, sans-serif', fontWeight: 'bold', color: '#00398F' }}>
                                i<span style={{ color: '#d47029' }}>Coach</span>Basketball {testEnvironmentIndication()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid paddingLeft={2} paddingRight={2} paddingTop={2} paddingBottom={2}>
                        {/* Fields */}
                        {
                            fields.filter(x => x.show).map((item, index) => (
                                item.id === 'field_phone_number'
                                    // Phone number
                                    ? <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '5px', width: '100%' }}>
                                        <Typography sx={{ marginBottom: '5px', fontFamily: 'PT Sans, sans-serif', fontSize: '16px', color: '#000', textAlign: 'left', width: '100%' }}>
                                            {item.label}
                                        </Typography>
                                        <MuiTelInput
                                            fullWidth
                                            name={item.id}
                                            id={item.id}
                                            value={phoneNumber}
                                            onChange={handleChangePhoneNUmber}
                                            sx={{
                                                marginBottom: 1,
                                                minWidth: 300,
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        border: '1px solid lightgrey', // Blue border in the default state
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        border: '2px solid #00398F', // Red border on focus
                                                    },
                                                }
                                            }} // Ajusta el ancho aquí
                                            inputProps={{
                                                maxLength: 60, // La longitud máxima del nombre de usuario es de 60 caracteres
                                                style: {
                                                    lineHeight: '1.5',
                                                    padding: '7px 0 7px 10px', // Ajusta el relleno aquí
                                                    fontFamily: 'PT Sans, sans-serif', // Establece la fuente aquí
                                                    fontSize: '16px',
                                                    height: '25px', // Ajusta la altura aquí
                                                    color: 'black',

                                                }
                                            }}
                                        />
                                    </Box>
                                    // Not phone number
                                    : <Box key={item.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                        <Typography sx={{ fontFamily: 'PT Sans, sans-serif', fontSize: '14px', color: '#000', textAlign: 'left', width: '100%' }}>
                                            {item.label}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            name={item.id}
                                            id={item.id}
                                            type={item.type}
                                            value={account[item.id as keyof TypeAccount]}
                                            // onChange={handleChange}

                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChange(event) }}

                                            select={item.selectinputtype}
                                            autoFocus={index === 0}
                                            sx={{
                                                marginBottom: '5px',
                                                minWidth: 300, // Adjusts the width here
                                                "& .MuiOutlinedInput-root": {
                                                    "& fieldset": {
                                                        border: '1px solid lightgrey', // Blue border in the default state
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                        border: '2px solid #00398F', // Red border on focus
                                                    },
                                                }
                                            }}
                                            InputProps={{
                                                style: {
                                                    lineHeight: '1.5',
                                                    padding: '10px', // Adjust the padding here to reduce height
                                                    fontFamily: 'PT Sans, sans-serif', // Set the font here
                                                    fontSize: '16px',
                                                    height: '40px', // Adjust the height here
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    borderRadius: '6px'
                                                }
                                            }}
                                        >
                                            {
                                                // Handle select drop down list if field is a selection field
                                                item.selectinputtype ?
                                                    item.selectinputtypeoptions!.map((x) => (
                                                        <MenuItem
                                                            key={x.value}
                                                            value={x.value}
                                                            onClick={() => {
                                                                let aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                                                                setCookie('icoachbasketball', x.value, { expires: aYearFromNow });
                                                                dispatch({ type: 'setLocale', locale: x.value })
                                                                i18n.changeLanguage(formatLanguage(x.value));
                                                            }}
                                                        >
                                                            {x.label}
                                                        </MenuItem>
                                                    ))
                                                    : ''
                                            }
                                        </TextField>
                                    </Box>
                            ))
                        }

                        {/* Captcha */}
                        {
                            ([AccountAction.signup, AccountAction.getnewpassword, AccountAction.resendemailverificationcode].includes(mode) &&
                                state.configuration.length > 0 &&
                                state.configuration[0].attributes.field_show_captcha) && (
                                <ReCAPTCHA
                                    ref={(r) => setCaptchaRef(r)}
                                    sitekey={getReCAPTCHASitekey()}
                                    onChange={onChangeRecaptcha}
                                />)
                        }

                        {/* Links */}
                        <Box display={'flex'} sx={{ justifyContent: 'space-between' }}>
                            {
                                buttons.filter(x => x.action !== mode && x.isVisible).map((item) => (
                                    <Button
                                        aria-label={`accountcaptcha_${item.id}`}
                                        key={item.label}
                                        variant="text"
                                        size="small"
                                        onClick={() => {
                                            handleClick(item.id);
                                            window.scrollTo(0, 0); // Desplaza la ventana hacia arriba
                                        }}
                                        sx={{ textTransform: 'none', color: '#00398F' }}
                                    >
                                        {item.label}
                                    </Button>
                                ))
                            }
                        </Box>

                        {/* Button to submit form */}
                        {
                            buttons.filter(x => x.action === mode && x.isVisible).map((item) => (
                                <Box key={item.id} display="flex" justifyContent="center">
                                    <Button
                                        aria-label={`accountcaptcha_${item.id}`}
                                        key={item.label}
                                        variant="contained"
                                        onClick={() => handleClick(item.id)}
                                        sx={{
                                            width: '100%',
                                            marginTop: 1,
                                            borderRadius: '5px',
                                            textTransform: 'none', // Removes text transformation
                                            fontSize: '0.8rem', // Adjusts font size
                                            color: 'white',
                                            backgroundColor: '#00398F',
                                        }}
                                        disabled={disabled}
                                    >
                                        {item.label}
                                    </Button>
                                </Box>
                            ))
                        }

                        {/* Show and accept terms and conditions */}
                        {
                            [AccountAction.signup].includes(mode) ?
                                <Fragment>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={1} sx={{ backgroundColor: 'white' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="acceptTermsAndConditions"
                                                    onChange={handleChangeAcceptTermsAndConditions}
                                                    checked={account["acceptTermsAndConditions"]}
                                                />
                                            }
                                            label={t("AccountCaptcha10")}
                                            sx={{
                                                '& .MuiFormControlLabel-label': {
                                                    fontFamily: 'PT Sans, sans-serif',
                                                    color: '#15468f',
                                                    fontSize: '12px',
                                                }
                                            }}
                                        />
                                        <Link
                                            component={Button}
                                            onClick={() => {
                                                // save data entered so far so data entered can be reestablished when we come back from terms and conditions
                                                let action: ActionSaveOnGlobalState = { type: 'setOnGlobalState', obj: account }
                                                dispatch(action);
                                                navigate('/termsandconditions')
                                            }}
                                            sx={{ fontFamily: 'PT Sans, sans-serif', textTransform: 'none', color: '#15468f', fontSize: '12px', marginLeft: 2 }} underline="none"
                                        >
                                            {t("AccountCaptcha11")}
                                        </Link>
                                    </Box>
                                </Fragment>
                                : ''
                        }

                        {/* Dropdown menu with langauge selection */}
                        <Grid container direction={'column'} justifyContent="center" alignItems="center" sx={{ marginTop: 1, marginBottom: 1, backgroundColor: 'white' }}>
                            {/* {
                                mode !== AccountAction.signup && <LanguageICB />
                            } */}

                            <Typography color={'darkgrey'} style={{ fontFamily: 'PT Sans, sans-serif', fontSize: '12px' }}>
                                {__APP_VERSION__}
                            </Typography>
                        </Grid>

                    </Grid>
                </div>
            </Box >
            {/* <Box height={150} /> // for scrolling on iPhone */}
            <Box height={150} />
        </Box>
    )
}
