import { Fragment, useContext, useEffect, useState } from "react";
import { ActionSetPlaybook, JSONAPITypeId, PropsPlayCard, TypeContext } from "../misc/Types";
import { Box, Typography, IconButton, Stack, Paper, Tooltip, Fab, TextField } from "@mui/material";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import Grid2 from "@mui/material/Unstable_Grid2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import AuthorImageAndName from "./AuthorImageAndName";
import { getActionSetConfirm, getNodeWithUpdatedRelationship, getRelationshipsDataElementFor } from "../misc/Functions";
import Favorite from "./Favorite";
import PlayIllustrate from "./PlayIllustrate";

// Main component PlayCard
export default function PlayCard({ play, index }: PropsPlayCard) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  // ? after play to protect against a case where the user no longer has access to the play
  const playDetails = play?.attributes.field_play_details ? JSON.parse(play.attributes.field_play_details) : {};
  const frames = playDetails?.frames || [];
  // const courtType = playDetails?.courtType || 'halfcourt'; // Get courtType from playDetails
  const theme = useTheme();

  const [showPlayOnePhase, setShowPlayOnePhase] = useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false);
  const [showPlayDescription, setShowPlayDescription] = useState(false);
  const [playNameCallOut, setPlayNameCallOut] = useState('');

  useEffect(() => {
    // play && play.id are not available after login and fast edit playbook
    if (play && play.id)
      setPlayNameCallOut(JSON.parse(state.curPlaybook.attributes.field_play_name_call_out || '{}')[play.id] || '')
  }, [state.curPlaybook, play])

  return (
    <Fragment>
      {/* Play card that is NOT selected for a playbook */}
      {index === undefined && (
        <Paper
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '350px',
            margin: 'auto',
            padding: state.portrait ? 1 : 1.5,
            borderRadius: state.portrait ? 2 : 5,
          }}
        >
          <Box display={'flex'} sx={{ flexDirection: "column" }}>
            <AuthorImageAndName node={play} />

            <Box sx={{ position: "relative", flexGrow: 1, marginTop: 1 }}>
              {frames.length > 0 && (

                // Button to add play to playbook
                <Box sx={{ position: "relative", flexGrow: 1 }}>
                  <Tooltip title={t('PlayCard04')} enterDelay={1000}>
                    <Fab
                      color="primary"
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: '40px',
                        right: '4px',
                        opacity: 0.7, // para añadir transparencia
                        transition: 'opacity 0.3s',
                        '&:hover': {
                          opacity: 0.9,
                          backgroundColor: '#00398F',
                          color: 'white',
                        },
                      }}
                      onClick={() => {
                        // Check play is not already in playbook
                        if (state.curPlaybook.relationships.field_plays?.data.map((x: JSONAPITypeId) => x.id).includes(play.id)) {
                          dispatch(getActionSetConfirm(t('PlayCard05')));
                          return
                        }
                        const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays?.data || [], getRelationshipsDataElementFor(play)]
                        const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                        dispatch(action);
                        setOpenSnackbarMessage(true);
                        setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
                      }}
                    >
                      <AddRoundedIcon />
                    </Fab>
                  </Tooltip>

                  {/* Show play in list of plays that are not selected */}
                  {/* bring play drupal_internal__nid all the way down to fct generateAnimationScript! */}
                  <PlayIllustrate
                    key={play.id}
                    playNid={play.attributes.drupal_internal__nid}
                    playDetails={playDetails} />
                </Box>
              )}
            </Box>

            <Box sx={{ display: "flex" }}>

              {/* Edit icon */}
              {play.relationships.uid.data.id === state.user.data.id && (
                <IconButton size="small" onClick={() => {
                  dispatch({ type: 'setPlay', play: play });
                  navigate("/createplay")
                }}>
                  <EditIcon />
                </IconButton>
              )}

              {/* Favorite icon */}
              <Favorite node={play} field='field_favorite_plays' />

              {/* Play name */}
              <Typography
                sx={{
                  fontSize: { xs: 11, sm: 14 },
                  lineHeight: 1,
                  fontWeight: "bold",
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: 1,
                }}
              >
                {play.attributes.title}
              </Typography>
            </Box>

            {/* toggle show description if description is very long */}
            {!state.portrait && play.attributes.field_play_description && (
              <>
                <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} >
                  {showPlayDescription
                    ? play.attributes.field_play_description
                    : play.attributes.field_play_description.substring(0, 150) + (play.attributes.field_play_description.length > 150 ? '...' : '')
                  }
                </Typography>
                {play.attributes.field_play_description.length > 150 && (
                  <Typography
                    onClick={() => setShowPlayDescription(!showPlayDescription)}
                    sx={{
                      textTransform: 'none',
                      paddingTop: 1,
                      fontSize: '12px',
                      cursor: 'pointer',
                      color: theme.palette.text.secondary,
                      textDecoration: 'underline',
                    }}
                  >
                    {showPlayDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Paper >
      )}

      {/* Play card IS selected for a playbook and user has access to play */}
      {index !== undefined && play && (
        <>
          <Box mt={index === 0 ? 0 : 1}>
            <Grid2
              container
              direction="column"
              sx={{
                backgroundColor: "#e1f5fe",
                width: "100%",
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                paddingLeft: 1,
                paddingBottom: 1,
              }}
            >
              {/* First row: index + title */}
              <Grid2
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >

                <Typography sx={{ fontSize: 12, fontWeight: "bold", color: '#00398F' }}>
                  {play.attributes.title || t("PlayCard02")}
                </Typography>
                <IconButton
                  onClick={() => setShowPlayOnePhase(!showPlayOnePhase)}
                  sx={{ marginLeft: 1 }}
                >
                  {showPlayOnePhase ? (
                    <VisibilityOffIcon fontSize="small" sx={{ color: '#00398F' }} />
                  ) : (
                    <VisibilityIcon fontSize="small" sx={{ color: '#00398F' }} />
                  )}
                </IconButton>
              </Grid2>

              {/* Second row: text field + toggle + up/down/delete icons */}
              <Grid2
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#00398F", marginRight: 1 }}>
                    {`${index + 1}.`}
                  </Typography>
                  <TextField
                    key={index}
                    size="small"
                    id="outlined-basic"
                    label={t('CreatePlay03')}
                    variant="outlined"
                    value={playNameCallOut}
                    onChange={(e) => {
                      setPlayNameCallOut(e.target.value);
                      const field_play_name_call_out: { [key: string]: string } = {};
                      const id = play.id!;
                      field_play_name_call_out[id] = e.target.value;
                      const action: ActionSetPlaybook = {
                        type: "setPlaybook",
                        playbook: {
                          ...state.curPlaybook,
                          attributes: {
                            ...state.curPlaybook.attributes,
                            field_play_name_call_out: JSON.stringify({
                              ...JSON.parse(
                                state.curPlaybook.attributes.field_play_name_call_out || "{}"
                              ),
                              ...field_play_name_call_out,
                            }),
                          },
                        },
                      };
                      dispatch(action);
                    }}
                  />

                </Box>

                <Stack direction="row" spacing={0} alignItems="center">
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        const selectedPlays = [
                          ...state.curPlaybook.relationships.field_plays.data,
                        ];
                        [selectedPlays[index - 1], selectedPlays[index]] = [
                          selectedPlays[index],
                          selectedPlays[index - 1],
                        ];
                        const action: ActionSetPlaybook = {
                          type: "setPlaybook",
                          playbook: getNodeWithUpdatedRelationship(
                            state.curPlaybook,
                            "field_plays",
                            selectedPlays
                          ),
                        };
                        dispatch(action);
                      }}
                    >
                      <ArrowCircleUpRoundedIcon fontSize="small" sx={{ color: "grey" }} />
                    </IconButton>
                  )}

                  {index !==
                    state.curPlaybook.relationships.field_plays.data.length - 1 && (
                      <IconButton
                        onClick={() => {
                          const selectedPlays = [
                            ...state.curPlaybook.relationships.field_plays.data,
                          ];
                          [selectedPlays[index], selectedPlays[index + 1]] = [
                            selectedPlays[index + 1],
                            selectedPlays[index],
                          ];
                          const action: ActionSetPlaybook = {
                            type: "setPlaybook",
                            playbook: getNodeWithUpdatedRelationship(
                              state.curPlaybook,
                              "field_plays",
                              selectedPlays
                            ),
                          };
                          dispatch(action);
                        }}
                      >
                        <ArrowCircleDownRoundedIcon
                          fontSize="small"
                          sx={{ color: "grey" }}
                        />
                      </IconButton>
                    )}

                  <IconButton
                    onClick={() => {
                      const selectedPlays =
                        state.curPlaybook.relationships.field_plays.data.filter(
                          (_item: JSONAPITypeId, i: number) => i !== index
                        );
                      const action: ActionSetPlaybook = {
                        type: "setPlaybook",
                        playbook: getNodeWithUpdatedRelationship(
                          state.curPlaybook,
                          "field_plays",
                          selectedPlays
                        ),
                      };
                      dispatch(action);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
                  </IconButton>
                </Stack>
              </Grid2>
            </Grid2>

            {showPlayOnePhase && (
              <Grid2 container alignItems="flex-start" padding={1} sx={{ flexGrow: 1 }}>
                <Grid2 sx={{ flexGrow: 1 }}>
                  {/* <PlayOnePhase frame={frames[currentFrameIndex]} courtType={courtType} /> */}
                  <PlayIllustrate key={play.id} playNid={0} playDetails={playDetails} />
                </Grid2>
              </Grid2>
            )}
          </Box>
        </>
      )}

      {/* Play card IS selected for a playbook and user has access NO to play */}
      {index !== undefined && !play && (
        <Box mt={index === 0 ? 0 : 1}>
          <Grid2
            sx={{
              backgroundColor: "#e1f5fe",
              width: "100%",
              height: '35px',
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: 14, fontWeight: "bold", color: '#00398F' }}>
                {`${index + 1}. ${t("PlayCard02")}`}
              </Typography>
            </Box>

            <Stack direction="row" spacing={0} alignItems="center">

              <IconButton
                onClick={() => {
                  const selectedPlays: JSONAPITypeId[] = state.curPlaybook.relationships.field_plays.data.filter((_item: JSONAPITypeId, i: number) => i !== index)
                  const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                  dispatch(action);
                }}
              >
                <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
              </IconButton>

            </Stack>
          </Grid2>

          {showPlayOnePhase && (
            <Grid2 container alignItems="flex-start" padding={1} sx={{ flexGrow: 1 }}>
              <Grid2 sx={{ flexGrow: 1 }}
              // onClick={() => {
              //   setOpenDialogPlayAnimation(true);
              // }}
              >
                {/* <PlayOnePhase frame={frames[currentFrameIndex]} courtType={courtType} /> */}
                <PlayIllustrate key={0} playNid={0} playDetails={playDetails} />
              </Grid2>
            </Grid2>
          )}
        </Box>
      )}

      <SnackbarMessages
        message={t('PlayCard03')}
        open={openSnackbarMessage}
        icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#00398F' }} />}
        color={'#00398F'}
        landscapeVerticalPosition='bottom'
        landscapeHorizontalPosition='right'
      />

    </Fragment>
  );
}