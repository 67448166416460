import { BACKEND } from "../misc/Constants";

export function BasketballHalfCourt() {
    // https://commons.wikimedia.org/wiki/File:Basketball_court_fiba.svg 
    return (
        <svg
            viewBox="57 28 430 410"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <pattern id="woodPattern" width="100" height="300" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="15" height="100" fill="#e4c49c" />
                    <rect x="11" y="0" width="15" height="100" fill="#e4cc9c" />
                    <rect x="22" y="0" width="15" height="100" fill="#e4c494" />
                    <rect x="33" y="0" width="15" height="100" fill="#dcbc94" />
                    <rect x="44" y="0" width="15" height="100" fill="#dcc49c" />
                    <rect x="55" y="0" width="15" height="100" fill="#e4cca4" />
                    <rect x="66" y="0" width="15" height="100" fill="#e4c8a0" />
                    <rect x="70" y="0" width="15" height="100" fill="#e4c49c" />
                    <rect x="81" y="0" width="15" height="100" fill="#e4cc9c" />
                    <rect x="92" y="0" width="15" height="100" fill="#e4c494" />

                    <rect x="0" y="70" width="15" height="100" fill="#e4c49c" />
                    <rect x="11" y="70" width="15" height="100" fill="#e4cc9c" />
                    <rect x="22" y="70" width="15" height="100" fill="#e4c494" />
                    <rect x="33" y="70" width="15" height="100" fill="#dcbc94" />
                    <rect x="44" y="70" width="15" height="100" fill="#dcc49c" />
                    <rect x="55" y="70" width="15" height="100" fill="#e4cca4" />
                    <rect x="66" y="70" width="15" height="100" fill="#e4c8a0" />
                    <rect x="70" y="70" width="15" height="100" fill="#e4c49c" />
                    <rect x="81" y="70" width="15" height="100" fill="#e4cc9c" />
                    <rect x="92" y="70" width="15" height="100" fill="#e4c494" />

                    <rect x="0" y="140" width="15" height="100" fill="#e4c8a0" />
                    <rect x="11" y="140" width="15" height="100" fill="#e4c49c" />
                    <rect x="22" y="140" width="15" height="100" fill="#e4cc9c" />
                    <rect x="33" y="140" width="15" height="100" fill="#e4c494" />
                    <rect x="44" y="140" width="15" height="100" fill="#dcbc94" />
                    <rect x="55" y="140" width="15" height="100" fill="#dcc49c" />
                    <rect x="66" y="140" width="15" height="100" fill="#e4cca4" />
                    <rect x="70" y="140" width="15" height="100" fill="#e4c8a0" />
                    <rect x="81" y="140" width="15" height="100" fill="#e4c49c" />
                    <rect x="92" y="140" width="15" height="100" fill="#e4cc9c" />

                    <rect x="0" y="200" width="15" height="100" fill="#e4c8a0" />
                    <rect x="11" y="200" width="15" height="100" fill="#e4c49c" />
                    <rect x="22" y="200" width="15" height="100" fill="#e4cc9c" />
                    <rect x="33" y="200" width="15" height="100" fill="#e4c494" />
                    <rect x="44" y="200" width="15" height="100" fill="#dcbc94" />
                    <rect x="55" y="200" width="15" height="100" fill="#dcc49c" />
                    <rect x="66" y="200" width="15" height="100" fill="#e4cca4" />
                    <rect x="70" y="200" width="15" height="100" fill="#e4c8a0" />
                    <rect x="81" y="200" width="15" height="100" fill="#e4c49c" />
                    <rect x="92" y="200" width="15" height="100" fill="#e4cc9c" />
                </pattern>
            </defs>
            <rect width="10000" height="10000" fill="url(#woodPattern)" />

            <g>
                {/* <g id="Floor_Markings">
                    <rect x="28.35" y="28.35" fill="none" width="484.72" height="853.23" />
                    <path fill="url(#woodPattern)" d="M484.72,56.69v796.54H56.69V56.69H484.72 M541.42,0H0v909.92h541.42V0L541.42,0z" />
                </g> */}
                <g id="Court_Markings">
                    <g id="Boundary_Line_and_Centre_Line">
                        <g id="Boundary_Line">
                            {/* left side line */}
                            <rect x="56.74" y="58.11" fill="#FFFFFF" width="3" height="794.36" />
                            {/* right side line */}
                            <rect x="483.31" y="58.11" fill="#FFFFFF" width="3" height="794.36" />
                            <rect x="56.74" y="56.69" fill="#FFFFFF" width="427.99" height="3" />
                            <rect x="56.74" y="852.48" fill="#FFFFFF" width="427.99" height="3" />
                        </g>
                        <rect x="52.53" y="454.59" fill="#FFFFFF" width="436.41" height="3" />
                        <path id="Centre_Circle_00000070833358215776187290000005064515555768438672_" fill="#FFFFFF" d="M270.73,405.65
				c27.35,0,49.6,22.27,49.6,49.65s-22.25,49.65-49.6,49.65c-27.35,0-49.6-22.27-49.6-49.65S243.38,405.65,270.73,405.65
				 M270.73,404.23c-28.18,0-51.02,22.86-51.02,51.07s22.84,51.07,51.02,51.07s51.02-22.86,51.02-51.07S298.91,404.23,270.73,404.23
				L270.73,404.23z"/>
                    </g>
                    <g id="_x33__Point_Line">
                        <rect x="83.7" y="767.3" fill="#FFFFFF" width="2.5" height="85.18" />
                        <rect x="456.38" y="767.43" fill="#FFFFFF" width="1.42" height="85.04" />
                        <path fill="#FFFFFF" d="M457.8,767.44l-1.41,0.1h-0.01c-2.47-11.52-6.04-22.79-10.67-33.74c-9.56-22.63-23.26-42.96-40.7-60.42
				c-17.44-17.45-37.75-31.16-60.36-40.73c-23.41-9.91-48.28-14.93-73.91-14.93c-25.64,0-50.51,5.02-73.92,14.93
				c-22.61,9.58-42.92,23.28-60.36,40.73c-17.44,17.46-31.13,37.79-40.7,60.42c-4.61,10.91-8.16,22.13-10.63,33.6h-0.01l-1.41-0.09
				c18.56-86.32,95.24-151.02,187.03-151.02C362.57,616.29,439.29,681.06,457.8,767.44z"/>
                    </g>
                    <g id="Key">
                        <g id="Key_Base">
                            <path fill="#FFFFFF" d="M338.76,689.35v163.13H202.71V689.35H338.76 M340.18,687.93H201.29v165.97h138.89V687.93L340.18,687.93z" />
                            <path fill="#FFFFFF" d="M321.74,687.93h-1.41c-0.37-27.05-22.48-48.94-49.6-48.94c-27.12,0-49.23,21.89-49.6,48.94h-1.41
					c0.37-27.87,23.06-50.36,51.01-50.36C298.68,637.57,321.37,660.05,321.74,687.93z"/>
                        </g>
                        <g id="Rebound_Places">
                            <rect x="340.18" y="801.41" fill="#FFFFFF" width="2.83" height="1.42" />
                            <rect x="340.18" y="765.94" fill="#FFFFFF" width="2.83" height="11.35" />
                            <rect x="340.18" y="740.41" fill="#FFFFFF" width="2.83" height="1.42" />
                            <rect x="340.18" y="714.88" fill="#FFFFFF" width="2.83" height="1.42" />
                            <rect x="198.46" y="801.41" fill="#FFFFFF" width="2.83" height="1.42" />
                            <rect x="198.46" y="765.95" fill="#FFFFFF" width="2.83" height="11.35" />
                            <rect x="198.46" y="740.41" fill="#FFFFFF" width="2.83" height="1.42" />
                            <rect x="198.46" y="714.88" fill="#FFFFFF" width="2.83" height="1.42" />
                        </g>
                    </g>
                    <g id="No-Charge_Circle">
                        <rect x="306.15" y="807.79" fill="#FFFFFF" width="1.42" height="10.64" />
                        <path fill="#FFFFFF" d="M307.57,807.79h-1.42c-0.37-19.23-16.11-34.76-35.42-34.76c-19.31,0-35.05,15.53-35.42,34.76h-1.42
				c0.37-20.05,16.72-36.18,36.84-36.18C290.85,771.61,307.2,787.75,307.57,807.79z"/>
                        <rect x="233.9" y="807.79" fill="#FFFFFF" width="1.42" height="10.64" />
                    </g>
                    <rect id="Throw-in_Line" x="52.48" y="614.87" fill="#FFFFFF" width="4.25" height="1.42" />
                    <g id="Team_Bench_Area">

                        <rect id="Team_Bench_00000088106750679376283670000012279022595219760517_" x="484.73" y="597.85" fill="#FFFFFF" width="56.69" height="3" />
                        <rect id="Team_Bench" x="484.73" y="852.48" fill="#FFFFFF" width="56.69" height="3" />
                    </g>
                    <g id="Half_Markings">
                        <g id="_x33__Point_Line_00000126295001213732848230000002648695590174514839_">
                            <rect x="83.6" y="58.11" fill="#FFFFFF" width="2.5" height="85.18" />
                            <rect x="456.28" y="58.11" fill="#FFFFFF" width="2.5" height="85.04" />
                            <path
                                fill="#FFFFFF"
                                stroke="#FFFFFF"
                                transform="translate(0.5,0)"
                                d="M457.7,143.15l-1.41-0.1h-0.01c-2.47,11.52-6.04,22.79-10.67,33.74c-9.56,22.63-23.26,42.96-40.7,60.42
    c-17.44,17.45-37.75,31.16-60.36,40.73c-23.41,9.91-48.28,14.93-73.91,14.93c-25.64,0-50.51-5.02-73.92-14.93
    c-22.61-9.58-42.92-23.28-60.36-40.73c-17.44-17.46-31.13-37.79-40.7-60.42c-4.61-10.91-8.16-22.13-10.63-33.6h-0.01l-1.41,0.09
    c18.56,86.32,95.24,151.02,187.03,151.02C362.46,294.29,439.19,229.53,457.7,143.15z"/>

                        </g>
                        <g id="Key_00000056419209107017033040000005191718291191303861_">
                            <g id="Key_Base_00000045580905087958776380000009480438004150356358_">
                                <path fill="#FFFFFF" d="M338.66,58.11v163.13H202.6V58.11H338.66 M340.07,56.69H201.19v165.97h138.89V56.69L340.07,56.69z" />
                                <path fill="#FFFFFF" d="M321.64,222.66h-1.41c-0.37,27.05-22.48,48.94-49.6,48.94c-27.12,0-49.23-21.89-49.6-48.94h-1.41
						c0.37,27.87,23.06,50.36,51.01,50.36C298.58,273.02,321.27,250.53,321.64,222.66z"/>
                            </g>
                            <g id="Rebound_Places_00000133531023428113855130000003839947234106507163_">
                                <rect x="340.07" y="107.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="340.07" y="133.29" fill="#FFFFFF" width="2.83" height="11.35" />
                                <rect x="340.07" y="168.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="340.07" y="194.29" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.35" y="107.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.35" y="133.29" fill="#FFFFFF" width="2.83" height="11.35" />
                                <rect x="198.35" y="168.76" fill="#FFFFFF" width="2.83" height="1.42" />
                                <rect x="198.35" y="194.29" fill="#FFFFFF" width="2.83" height="1.42" />
                            </g>
                        </g>
                        <g id="No-Charge_Circle_00000119105268069419404570000008776237732058513808_">
                            <rect x="306.05" y="92.16" fill="#FFFFFF" width="1.42" height="10.64" />
                            <path fill="#FFFFFF" d="M307.47,102.79h-1.42c-0.37,19.23-16.11,34.76-35.42,34.76s-35.05-15.53-35.42-34.76h-1.42
					c0.37,20.05,16.72,36.18,36.84,36.18S307.1,122.84,307.47,102.79z"/>
                            <rect x="233.79" y="92.16" fill="#FFFFFF" width="1.42" height="10.64" />
                        </g>

                        <rect id="Throw-in_Line_00000155855732892319826830000001737842726948715944_" x="52.38" y="294.29" fill="white" width="4.25" height="2.5" />




                        <g id="Team_Bench_Area_00000074407288892148501920000002787382391698087838_">

                            <rect id="Team_Bench_00000085214562878665392920000002217376610200284599_" x="484.62" y="311.32" fill="#FFFFFF" width="56.69" height="2.5" />

                            <rect id="Team_Bench_00000018940718475814950360000009421589212662107563_" x="484.62" y="56.69" fill="#FFFFFF" width="56.69" height="2.5" />
                        </g>
                    </g>
                </g>
            </g>

            {/* Next line to show logo when this component is used in PlayCard */}
            <image x="70" y="372" width="50" height="50" href={`${BACKEND}/logoICBWhite.webp`} />
        </svg>
    )
};
