import { useCallback, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { ActionSetContentEntity, ActionSetPractice, TypeContext } from "../misc/Types";
import { DataGrid, GridActionsColDef, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { PRACTICEINIT } from "../misc/Constants";
import { createNode, deleteActionForDataGrid, getActionSetConfirm, icbControllerGenerel02, showMessage, updateNode } from "../misc/Functions";
import AddBUttonAboveLists from "./AddButtonAboveList";
import { randomId } from "@mui/x-data-grid-generator";

export default function MyTeams() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyTeams');
    const theme = useTheme();

    // find all rows
    function allRows() {
        const allRowsLocal = state.allTeams
            .filter(x => x.relationships.uid.data.id === state.user.data.id)
            .map(x => {
                return {
                    id: x.id,
                    title: x.attributes.title,
                }
            })
        return allRowsLocal
    }

    const [disabled, setDisabled] = useState(false)
    const [rows, setRows] = useState(allRows())

    // reset disabled after team has been delete
    useEffect(() => {
        setDisabled(false)
        const allRowsLocal = allRows()
        setRows(allRowsLocal)
    }, [state.allTeams, state.user.data.id])

    // data columns
    const columns: (GridColDef | GridActionsColDef)[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'title', headerName: t('PracticeProgramForm22'), flex: 1, editable: true, },
        deleteActionForDataGrid(handleDelete)
    ]

    // see https://mui.com/x/react-data-grid/editing/ for mutation
    const useMutation = (newRow: GridRowModel) => {
        if (state.allTeams.find(x => x.id === newRow.id)) {
            return updateNode(state, dispatch, 'team', { id: newRow.id, title: newRow.title.trim() }, state.allTeams.find(x => x.id === newRow.id)?.attributes.drupal_internal__nid)
        } else
            return createNode(state, dispatch, 'team', { title: newRow.title.trim() })
    };

    const mutateRow = (newRow: GridRowModel) => useMutation(newRow);

    const processRowUpdate = useCallback(
        async (newRow: GridRowModel) => {
            if (newRow.title.trim() === '')
                throw new Error(t('MyTeams01'))
            const response = await mutateRow(newRow);
            if (response.id) // if we don't get an id then the updateNode/createNode failed
                return response;
            else {
                throw new Error(response.message)
            }
        },
        [mutateRow],
    );

    // // Delete team
    // function handleDelete(id: string) {
    //     dispatch(getActionSetConfirm(t('MyTeams03'), '', () => {
    //         if (disabled)
    //             return
    //         setDisabled(true)
    //         // delete team and practices for team. Do this as back end job!
    //         icbControllerGenerel02(state, {
    //             opr: "delete_team",
    //             teamID: id
    //         })
    //             .then((resp) => {
    //                 if (resp.ok) {
    //                     // team and practices have been removed from the back end - remove from local lists
    //                     // remove practices from list of read practices
    //                     const actionSetContentEntityPractices: ActionSetContentEntity = {
    //                         type: 'setContentEntity',
    //                         contentType: 'node--practice',
    //                         data: state.allPractices.filter(x => x.relationships.field_team.data.id !== id),
    //                         initialLoad: true
    //                     }
    //                     dispatch(actionSetContentEntityPractices)
    //                     // remove team from list of read teams
    //                     const actionSetContentEntityTeams: ActionSetContentEntity = {
    //                         type: 'setContentEntity',
    //                         contentType: 'node--team',
    //                         data: state.allTeams.filter(x => x.id !== id),
    //                         initialLoad: true
    //                     }
    //                     dispatch(actionSetContentEntityTeams)
    //                     // init current practice if current practice belongs to team just deleted
    //                     if (state.curPractice.team && id === state.curPractice.team.id) {
    //                         const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
    //                         dispatch(action)
    //                     }
    //                 }
    //             })
    //     }))
    // }

    // Delete team
    async function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('MyTeams03'), '', async () => {
            if (disabled)
                return
            setDisabled(true)
            // delete team and practices for team. Do this as back end job!
            const resp = await icbControllerGenerel02(state, {
                opr: "delete_team",
                teamID: id
            })
            if (resp.ok) {
                // team and practices have been removed from the back end - remove from local lists
                // remove practices from list of read practices
                const actionSetContentEntityPractices: ActionSetContentEntity = {
                    type: 'setContentEntity',
                    contentType: 'node--practice',
                    data: state.allPractices.filter(x => x.relationships.field_team.data.id !== id),
                    initialLoad: true
                }
                dispatch(actionSetContentEntityPractices)
                // remove team from list of read teams
                const actionSetContentEntityTeams: ActionSetContentEntity = {
                    type: 'setContentEntity',
                    contentType: 'node--team',
                    data: state.allTeams.filter(x => x.id !== id),
                    initialLoad: true
                }
                dispatch(actionSetContentEntityTeams)
                // init current practice if current practice belongs to team just deleted
                if (state.curPractice.team && id === state.curPractice.team.id) {
                    const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
                    dispatch(action)
                }
            }
        }))
    }

    function handleAddRow() {
        // dont allow new row if we already new empty row
        if (rows.find(x => x.title === ''))
            return;
        setRows([...rows, { id: randomId(), title: '' }]);
    };

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('MyTeams02')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* Button to add preplanned practice */}
            <AddBUttonAboveLists
                buttonText={t('Generel12')}
                addCode={() => { handleAddRow() }}
            />

            <DataGrid
                sx={{
                    marginTop: 3,
                    '& .MuiInputBase-input': {
                        fontSize: '16px', // we prevent autozoom of the row when editing
                    }
                }}
                columns={columns}
                rows={rows}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(err) => {
                    // this could happen if two clients create team with same name
                    showMessage(dispatch, err.message)
                    log.info(err.message)
                }}
                rowHeight={30}
            />
        </Box >
    )
}